import React from 'react';
import classNames from 'classnames';
import { string, bool, number, shape } from 'prop-types';
import Image from '../../../../commons/image';
import PricesValues from '../prices-values';

const LoyaltyBuyLevel6MobileHeader = (props) => {
  const {
    title = '',
    pricing: {
      from = null,
      time = '',
      price = {
        units: '',
        cents: '',
      },
      currency: symbol = '',
    } = {},
    gradient_color = '',
    is_evolution = false,
    logo = '',
    currency_id = '',
    deviceType = '',
  } = props;

  const DEG_LOYALTY = gradient_color?.deg ?? '90deg';
  const FIRST_COLOR_LOYALTY = gradient_color?.first_color ?? '#a90f90';
  const PERCENTAGE_LOYALTY = gradient_color?.percentage ?? '55%';
  const SECOND_COLOR_LOYALTY = gradient_color?.second_color ?? '#0c1a51';
  return (
    <div
      className="loyalty-buylevel6__header"
      style={{
        '--deg_loyalty': DEG_LOYALTY,
        '--first_color_loyalty': FIRST_COLOR_LOYALTY,
        '--percentage_loyalty': PERCENTAGE_LOYALTY,
        '--second_color_loyalty': SECOND_COLOR_LOYALTY,
      }}
    >
      {logo && (
        <Image
          {...logo}
          className="loyalty-buylevel6__header__title-img"
          lazyLoad
          alt={logo.alt}
        />
      )}
      <div
        className={classNames('loyalty-buylevel6__header__title', {
          'loyalty-buylevel6__header__title--evolution': is_evolution,
        })}
      >
        <span className="loyalty-buylevel6__header__title-text">{title}</span>
        {price.units !== '' && price.cents !== '' && (
          <PricesValues
            price={price}
            from={from}
            time={time}
            is_evolution={is_evolution}
            currency_id={currency_id}
            symbol={symbol}
            deviceType={deviceType}
          />
        )}
      </div>
    </div>
  );
};

LoyaltyBuyLevel6MobileHeader.propTypes = {
  title: string.isRequired,
  title_size: number,
  pricing: shape({
    from_label: string,
    description: string,
    from: string,
    currency: string,
    is_pill_description_on: bool,
    is_only_pill: bool,
    is_feature_on: bool,
    time: string,
    price: shape({
      units: string.isRequired,
      cents: number,
    }),
  }).isRequired,
  gradient_color: shape({
    deg: string,
    first_color: string,
    percentage: string,
    second_color: string,
  }),
  is_evolution: bool,
  logo: shape({
    title: string,
    src: string,
    alt: string,
  }),
  deviceType: string,
  currency_id: string,
};

export default LoyaltyBuyLevel6MobileHeader;
