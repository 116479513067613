import React from 'react';
import classNames from 'classnames';
import { string, shape, arrayOf } from 'prop-types';
import Section from '../../../../commons/section';
import BenefitItem from '../benefit-item';
import LoyaltyBuyLevel6MobileHeader from './header';
import IconChevron from '../../../../commons/icons/chevron';
import withTracker from '../../../../commons/with-tracker';

const LoyaltyBuyLevel6Mobile = ({
  header,
  benefits,
  benefits_title,
  deviceType,
  action,
  country: { currency_id = '' } = {},
}) => (
  <Section
    className={classNames('loyalty-buylevel6', {
      'loyalty-buylevel6__tablet': deviceType === 'tablet',
    })}
  >
    <LoyaltyBuyLevel6MobileHeader
      {...header}
      currency_id={currency_id}
      deviceType={deviceType}
    />
    <div className="loyalty-buylevel6__body">
      <span className="loyalty-buylevel6__body-title">{benefits_title}</span>
      <div className="loyalty-buylevel6__body-benefits">
        {benefits.map((item) => (
          <BenefitItem key={item.description} {...item} />
        ))}
      </div>
    </div>
    {action && (
      <a href={action.target} className="loyalty-buylevel6__button">
        <span className="loyalty-buylevel6__button-text">
          {action.label?.text}
        </span>
        <IconChevron aria-hidden="true" />
      </a>
    )}
  </Section>
);

LoyaltyBuyLevel6Mobile.propTypes = {
  header: shape({}),
  benefits: arrayOf(
    shape({
      icon: shape({
        url: shape({
          src: string,
        }),
      }),
      description: string,
    }),
  ),
  benefits_title: string,
  action: shape({
    label: shape({}),
    target: string,
  }),
  deviceType: string,
  country: shape({
    currency_id: string,
  }),
};

export default withTracker(LoyaltyBuyLevel6Mobile);
